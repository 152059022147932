<template>
  <el-dialog v-bind='$attrs' v-on='$listeners' @open='onOpen'
             :close-on-click-modal="false" id="my-element" @close='onClose'>
    <template slot='title'>
      <div style="height: 0">
        <el-link class='pptTitle' @click='getDataList'>{{ KolNickname }} {{ dialogTitle }}</el-link>
        <svg-icon style='cursor: pointer' @click='changeFull'
                  :icon-class="fullscreen?'offScreen':'fullscreen'"></svg-icon>
        <el-button icon="el-icon-edit" style="margin-left: 10px" type="success"
                   @click="editData('opt_remark',formData.opt_remark)">
          运营备注
        </el-button>
      </div>
    </template>
    <div class="alertPage" v-show="loading">
      <img :src="loadingImg">
      {{ loadingText }}
    </div>

    <div :class="{'animate__animated': true, 'animate__fadeInLeft': showData,'animate__fadeOutRight': !showData}"
         class='preview-box'
         style="overflow:hidden">
      <transition name='el-fade-in-linear' v-if='formData?.state>0'>
        <el-image v-show='showStateImg' class='stateImg' :src='stateImg[`state_${formData.state}`]'></el-image>
      </transition>

      <el-row :gutter='24' class='base-info'>
        <el-col :span='18' class="box">
          <div v-if="isHightKickback" class="hightKickback">
            <img :src="high_kickback"/>
            <span>{{formData.online_cmn}} %
              <br/>{{ formData.ad_space_fee ? formData.ad_space_fee : '' }}</span>

          </div>
          <div style="max-height: 434px">
            <div class='title' style='display: flex;justify-content: space-between;'>
              <span>{{ formData.company_name }}</span>
              <b v-if='formData&&formData.updated_at'
                 style='color: darkred;font-size: 14px;'>（商品最后更新时间：{{ formData.updated_at }}）</b>
            </div>
            <el-descriptions :labelClassName="'labelClass'" :contentClassName="'contentClass'" class="margin-top"
                             :column="1" :size="'mini'">
              <el-descriptions-item>
                <template slot="label">
                  商品名称及规格
                </template>
                <span>{{ formData.title }}({{ formData.spec_name }})</span>

              </el-descriptions-item>
              <template v-for="(item,index) in configData">
                <el-descriptions-item :key="index" v-if="item.isShow">
                  <template slot="label">
                    {{ item.title }}
                  </template>
                  <span :style="item.color?{color:item.color}:''" v-for="i in item.value" :key="i"
                        style="font-size: 22px">
                    <template v-if="!(['special_remark','other_activity'].includes(i))">{{ formData[i] || '/' }} {{
                        formData[i] ? item.unit : ''
                      }}</template>
                      <template v-if="(['online_cmn'].includes(i))">
                        &nbsp;
                      </template>
                    <template v-else-if="i==='special_remark'&&formData.unify===2">{{
                        formData[i] || '/'
                      }} {{ formData[i] ? item.unit : '' }}</template>
                    <template v-else-if="i==='other_activity'&&formData.exclusive===1">{{
                        formData[i] || 'znw'
                      }}   {{ formData[i] ? item.unit : '' }}</template>
                  </span>
                  <i v-if='isPreview' class="el-icon-close" @click="handleClose(item,false)"></i>
                </el-descriptions-item>
              </template>
            </el-descriptions>

          </div>
          <el-row class='light-spot-box'>
            <div class='light-spot-title' @dblclick='editData("light_spot",formData.light_spot)'>亮点卖点（简洁）
            </div>
            <div class='light-spot-content' @dblclick='editData("light_spot",formData.light_spot)'
                 v-html='formData.light_spot'>
            </div>
          </el-row>
        </el-col>
        <el-col :span='6'>
          <div class='cover-box' @dblclick="editImgBtn">
            <div class='cover-title'>
              商品图片
            </div>
            <div class='cover-view'>
              <el-image fit='cover' style="width:300px;height: 300px"
                        :src='formData.cover_url'>
                <div slot="error" class="image-slot" style="width: 100%">
                  <div class="image-slot">
                    o(╥﹏╥)o
                    <br/>
                    <span>暂无图片</span>
                  </div>
                </div>
              </el-image>
            </div>
          </div>
          <div class="introBox">
            <div class='intro' v-html="formData.intro "
                 @dblclick.prevent='editData("intro",formData.intro)'></div>
          </div>

        </el-col>
      </el-row>
    </div>
    <div class='opt-box'>
      <el-row>
        <div class="configure" v-if='isPreview'>
          <el-tag
              style="cursor: pointer;"
              @click="handleClose(tag,true)"
              type="info"
              size="medium"
              effect="plain"
              v-for="tag in closeList"
              :key="tag.title"
          >
            {{ tag.title }}
          </el-tag>
        </div>
      </el-row>
      <el-row :gutter='10'>
        <el-col :span='4'>
          <el-button :disabled="showIndex===0" size='medium' icon='el-icon-arrow-left' @click='preBtn'>上一页
          </el-button>
        </el-col>

        <el-col :span='16' style='text-align: center'>
          <el-button-group v-if='!isPreview && userPermissions.indexOf("lb_kol_selection_state")>-1'>
            <el-button :type="formData?.state===item.value?'primary':''" size='medium' v-for='item in stateOptions'
                       :key='item.value' :disabled="loading"
                       @click='onchange(item.value)'>{{ item.label }}
            </el-button>
            <el-button @click="localNextOne">
              <el-checkbox @change="localNextOne" v-model="isNextOne">自动下一页</el-checkbox>
            </el-button>
          </el-button-group>
        </el-col>
        <el-col :span='4' style='text-align: right;float: right'>
          <el-button size='medium'
                     :disabled="showIndex === dataList.length - 1" @click='nextBtn'>下一页<i
              class='el-icon-arrow-right'></i>
          </el-button>
        </el-col>
      </el-row>
    </div>
    <el-dialog
        width="70%"
        @close='closeEdit'
        :destroy-on-close="true"
        :visible.sync="introVisible"
        append-to-body>
      <template slot='title'>
            <span class="pptTitle" style="font-size: 18px">
              修改{{ addTitle }}</span>
        <el-button style="float: right;margin-right: 50px" @click='saveEdit' type='primary'>保存
        </el-button>
      </template>
      <ApeEditor
          v-if="this.editType==='light_spot'&&introVisible"
          :editorHeight="500"
          :inline='true'
          :init-html='editContent'
          :toolbar="'undo redo paste | bold italic underline strikethrough | fontselect fontsizeselect |  forecolor backcolor | ' +
          'ltr rtl | alignleft aligncenter alignright alignjustify | bullist numlist' +
          ' | outdent indent blockquote | removeformat anchor hr |' +
          ' code preview fullscreen'"
          @handleTinymceInput='handleTinymceInput'></ApeEditor>
      <el-input v-else type="textarea" v-model="editContent" :rows="30"></el-input>
    </el-dialog>
    <el-dialog :destroy-on-close="true" :visible.sync="editImgDialog" append-to-body title="商品图片替换" width="20%">
      <ApeUploader :limit='1' :upload-file-list='uploadFileList'
                   uploadTip='请上传图片，最多上传1份。大小限制2M文件'
                   @handleUploadRemove='handleUploadRemove'
                   @handleUploadSuccess='handleUploadSuccess'></ApeUploader>
      <br/>
      <div style="text-align: center;">
        <el-button type="primary" @click="saveImg">提交</el-button>
        <el-button @click="cancelBtn">取消</el-button>
      </div>
    </el-dialog>

  </el-dialog>

</template>

<script>
import ApeEditor from '@/components/ApeEditor'
import state_1 from '@/assets/imgs/1.png'
import state_2 from '@/assets/imgs/2.png'
import state_3 from '@/assets/imgs/3.png'
import state_4 from '@/assets/imgs/4.png'
import state_5 from '@/assets/imgs/5.png'
import high_kickback from '@/assets/imgs/high-kickback.png'
import loadingImg from '@/assets/icons/lbLoading.png'
import 'animate.css'
import { mapGetters } from 'vuex'
import ApeUploader from '@/components/ApeUploader'

export default {
  name: 'SelectionPreview',
  props: {
    lbId: {
      type: [Number, String]
    },
    currentIndex: {
      type: Number,
      default() {
        return 0
      }
    },
    searchCondition: {
      type: Object,
      default() {
        return {}
      }
    },
    KolNickname: {
      type: String
    },
    typeData: {
      type: String
    },
    listData: {
      type: Array,
      default() {
        return []
      }
    }

  },
  components: {
    ApeEditor, ApeUploader
  },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo']),
    dialogTitle() {
      return `${this.isPreview ? '预览' : '选品'}(${this.showIndex + 1}/${this.dataList.length})`
    },
    isPreview() {
      //如果是预览就不要显示按钮和显示全部的列表
      return this.typeData === 'preview'
    },
    isHightKickback() {
      if (this.formData) {
        return this.formData.ad_space_fee > 0 || this.formData.online_cmn > 35 || this.formData.offline_cmn > 35
      } else return false
    },
    addTitle() {
      return this.editType === 'intro' ? '品牌背书' : this.editType === 'light_spot' ? '产品亮点' : '运营备注'
    },
    closeList() {
      let data = this.configData.map(i => {
        return i.isShow === false ? i : null
      }).filter(Boolean)
      return data
    }
  },
  data() {
    return {
      formData: {},
      stateOptions: [
        { label: '上播', value: 1, color_value: '#FFFFFF' },
        { label: '不上播', value: 2, color_value: '#67C23A' },
        { label: '红人试用', value: 3, color_value: '#409EFF' },
        { label: '团队试用', value: 4, color_value: '#E6A23C' },
        { label: '待定/备选', value: 5, color_value: '#E6A23C' }
      ],
      dataList: [],
      fullscreen: true,
      stateImg: {
        state_1: state_1,
        state_2: state_2,
        state_3: state_3,
        state_4: state_4,
        state_5: state_5
      },
      showStateImg: true,
      showIndex: 0,
      introVisible: false,
      editContent: '',
      editValue: '',
      editType: '',
      isNextOne: false,
      loading: false,
      loadingImg: loadingImg,
      showData: true,
      loadingText: '',
      uploadFileList: [],
      editImgDialog: false,
      editImg: '',
      configData: [
        { title: '店铺评分', value: ['company_score'], isShow: true, unit: '分' },
        { title: '坑位费', value: ['ad_space_fee'], isShow: true },
        { title: '线上/线下佣金', value: ['online_cmn', 'offline_cmn'], isShow: true, color: '#F56C6C', unit: '%' },
        { title: '日常价', value: ['price'], isShow: true },
        { title: '直播价', value: ['lb_price'], isShow: true, color: '#F56C6C' },
        { title: '赠品', value: ['gift'], isShow: true },
        { title: '是否全网统一机制', value: ['_unify', 'special_remark'], isShow: true, color: '#F56C6C' },
        { title: '是否有签独家', value: ['_exclusive', 'other_activity'], isShow: true, color: '#F56C6C' },
        { title: '是否可签保价1月', value: ['_support_value'], isShow: true, color: '#F56C6C' },
        { title: '历史销量', value: ['sales_volume'], isShow: true },
        { title: '备注', value: ['remark'], isShow: true }
      ],
      high_kickback: high_kickback
    }
  },
  methods: {
    localNextOne() {
      this.isNextOne = !this.isNextOne
      localStorage.setItem('lbNextOne', this.isNextOne)
    },
    closeEdit() {
      this.introVisible = false
    },
    editData(file, value) {
      this.introVisible = true
      this.editType = file
      this.editContent = value
    },
    async onOpen() {
      this.showIndex = this.currentIndex
      await this.getDataList()
      this.isNextOne = localStorage.getItem('lbNextOne') === 'true'
      this.fullscreen = localStorage.getItem('lbFullscreen') === 'true'
      this.fullscreen ? this.openFullscreen() : ''
    },
    onClose() {
      //记录关闭的时候 是全屏还是非全屏
      document.fullscreenElement !== null ? localStorage.setItem('lbFullscreen', 'true') : localStorage.setItem('lbFullscreen', 'false')
      this.closeFullscreen()
      this.dataList = []
      this.showIndex = 0
      this.formData = {}
      this.$emit('update:currentIndex', this.showIndex)
    },

    changeFull() {
      this.fullscreen = !this.fullscreen
      this.fullscreen ? this.openFullscreen() : this.closeFullscreen()
      localStorage.setItem('lbFullscreen', this.fullscreen)
    },
    async saveEdit() {
      if (this.editType === 'intro') {
        this.formData.intro = this.editContent || ''
        await this.editCellVal('intro', this.formData.intro)
      } else if (this.editType === 'light_spot') {
        this.formData.light_spot = this.editContent || ''
        await this.editCellVal('light_spot', this.formData.light_spot)
      } else if (this.editType === 'opt_remark') {
        this.formData.opt_remark = this.editContent || ''
        await this.editCellVal('opt_remark', this.formData.opt_remark)
      }
      this.introVisible = false
    },
    async editCellVal(field, value) {
      if (this.formData.id) {
        let id = await this.$api.singleSaveLbScheduleProduct(this.formData.id, field, value)
        if (id) {
          this.formData[field] = value
          this.$set(this.dataList[this.showIndex], field, value)
          this.$message.success('保存成功！')
          setTimeout(() => {
            this.showStateImg = true
            if (this.isNextOne && field === 'state') {
              setTimeout(() => {
                !(this.showIndex === this.dataList.length - 1) ? this.nextBtn() : ''
                this.loading = false
              }, 1000)
            }
          }, 600)
          this.$forceUpdate()

        }
      } else {
        this.$message.warning('参数错误，无法提交！！！')
      }
    },
    handleTinymceInput(val) {
      this.editContent = val
    },
    onchange(value) {
      // userPermissions.indexOf("lb_kol_selection_state")>-1
      this.showStateImg = false
      this.stateChange(value)
      this.formData.state = value
      this.isNextOne && !(this.showIndex === this.dataList.length - 1) ? this.loading = true : ''

    },
    async stateChange(item) {
      if (!this.isMultiple) {
        let data = {
          ids: [this.formData.id],
          state: item
        }
        let id = await this.$api.updataKolSelectionState(data)
        if (id) {
          // this.formData['state'] = value
          // this.$set(this.dataList[this.showIndex], 'state', value)
          this.$message.success('保存成功！')
          setTimeout(() => {
            this.showStateImg = true
            if (this.isNextOne) {
              setTimeout(() => {
                !(this.showIndex === this.dataList.length - 1) ? this.nextBtn() : ''
                this.loading = false
              }, 1000)
            }
          }, 600)
          this.$forceUpdate()
        }
      }

      // updataKolSelectionState
    },
    close() {
      this.$emit('update:visible', false)
    },
    nextBtn() {
      this.loadingText = '正在进入下一页...'
      this.showData = false
      this.loading = true
      // this.getData()
      setTimeout(() => {
        if (!(this.showIndex === this.dataList.length - 1)) {
          this.showIndex++
        }
        this.getData()
      }, 1000)

    },
    preBtn() {
      this.loadingText = '正在进入上一页...'
      this.showData = false
      this.loading = true
      // this.getData()
      setTimeout(() => {
        if (!(this.showIndex === 0)) {
          this.showIndex--
        }
        this.getData()
      }, 1000)
    },
    async getData() {
      this.formData = {}
      let obj = this.dataList[this.showIndex]
      this.formData = obj
      this.uploadFileList = [{ url: obj.cover_url }]
      this.loading = false
      this.showData = true
    },
    async getDataList() {
      // isPreview
      let searchCondition = { lb_id: this.lbId, ...this.searchCondition }
      if (!this.isPreview) {
        searchCondition.is_ack = 'Y'
      }
      let { list } = await this.$api.getLbScheduleKolSelection(searchCondition)
      if (list.length === 0) {
        this.$notify.error('暂无已确认选品,请到PPT预览')
        this.showData = false
        setTimeout(() => {
          this.close()
        }, 600)
      } else {
        this.$nextTick(() => {
          this.dataList = list
          this.getData()
        })
      }
    },
    openFullscreen() {
      if (this.fullscreen) {
        if (document.fullscreenEnabled || document.mozFullScreenEnabled || document.webkitFullscreenEnabled || document.msFullscreenEnabled) {
          const element = document.getElementById('my-element')
          if (element.requestFullscreen) {
            element.requestFullscreen()
          } else if (element.mozRequestFullScreen) { // Firefox
            element.mozRequestFullScreen()
          } else if (element.webkitRequestFullscreen) { // Chrome, Safari and Opera
            element.webkitRequestFullscreen()
          } else if (element.msRequestFullscreen) { // Internet Explorer and Edge
            element.msRequestFullscreen()
          }
        }
      }
    },
    closeFullscreen() {
      if (document.exitFullscreen && document.fullscreenElement !== null) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) { // Firefox
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) { // Internet Explorer and Edge
        document.msExitFullscreen()
      }
    },
    handleFullscreenChange() {
      if (document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement) {
        this.fullscreen = true
      } else {
        this.fullscreen = false
      }
      localStorage.setItem('lbFullscreen', this.fullscreen)
    },
    editImgBtn() {
      this.editImgDialog = true
    },
    async saveImg() {
      let postData = {
        id: this.formData.product_id,
        cover: this.editImg.cover,
        cover_url: this.editImg.full_path
      }
      let id = await this.$api.saveProductPart('formBase', postData)
      if (id) {
        this.$message.success('成功')
        this.formData.cover_url = this.editImg.full_path
        this.editImgDialog = false
      }
    },
    // 图片删除回调
    handleUploadRemove() {
      this.editImg = this.formData.cover_url
    },
    handleUploadSuccess(file) {
      this.editImg = {
        full_path: file.full_path,
        cover: file.id
      }
    },
    cancelBtn() {
      this.editImg = {}
      this.editImgDialog = false
    },
    async handleClose(item, value) {
      item.isShow = value
      // localStorage.setItem('lbPreviewRow', JSON.stringify(this.configData))
      let data = {
        type: 'product_slide',
        settings: JSON.stringify(this.configData)
      }
      await this.$api.saveMySetting(data)
    },
    async getPptUserSetting() {
      let data = {
        type: 'product_slide',
        user_id: this.userInfo.id
      }
      let { info } = await this.$api.getMySetting(data)
      const tableSet = info.settings ? JSON.parse(info.settings) : null
      if (tableSet) this.configData = tableSet
    }

  },
  mounted() {
    let _t = this
    document.addEventListener('fullscreenchange', this.handleFullscreenChange)
    document.addEventListener('mozfullscreenchange', this.handleFullscreenChange) // Firefox
    document.addEventListener('webkitfullscreenchange', this.handleFullscreenChange) // Chrome, Safari and Opera
    document.addEventListener('MSFullscreenChange', this.handleFullscreenChange) // Internet Explorer and Edge
    document.onkeydown = function(event) {
      if (event.key === 'ArrowRight' || event.key === 'ArrowUp') {
        _t.nextBtn()
      }
      if (event.key === 'ArrowLeft' || event.key === 'ArrowDown') {
        _t.preBtn()
      }
    }
    this.getPptUserSetting()
    // const tableSet = JSON.parse(localStorage.getItem('lbPreviewRow'))
    // if(tableSet) this.configData=tableSet
  }
}
</script>

<style lang='scss' scoped>

.preview-box {
  color: black;
  height: 90%;
  overflow: hidden;

  .title {
    font-size: 24px;
    font-weight: bold;
    line-height: 26px;
    background-color: #f2f2f2;
    padding: 6px;
  }

  .base-info {
    flex: 8;
    font-size: 16px;
    line-height: 24px;
    font-family: "Microsoft YaHei";

    .el-row {
      padding: 0 10px;
    }
  }

  .base-line {
    padding: 10px;
  }

  .light-spot-title {
    font-size: 24px;
    font-weight: bold;
    padding-top: 10px;
  }

  .light-spot-box {
    width: 100%;
    height: calc(90vh - 434px);
    background-color: #f2f2f2;
    text-align: left;
    vertical-align: top;
    z-index: 20;

    //padding: 0 10px;

    .light-spot-content {
      margin-top: 10px;
      max-height: 90%;
      overflow: scroll;
      overflow-y: scroll;
      font-size: 16px;

      > ::v-deep p {
        margin: 0 !important;
      }
    }
  }
}

.light-spot-edit {
  margin-top: 10px;
  height: 100%;
  overflow: scroll;
}


.cover-box {
  flex: 4;
  background-color: #F2F6FC;
  margin-left: 5px;
  width: 100%;
  min-height: 400px;
}

.cover-title {
  text-align: center;
  width: 100%;
  line-height: 30px;
  font-weight: bold;
  font-size: 28px;
  padding: 10px 0;
}

.cover-view {
  padding: 10px 0;
  //height: 50%;

  > * {
    width: 200px;
    display: block;
    margin: 0 auto;
    padding: 10px;
  }

}

.introBox {
  margin-top: 10px;

  border: 1px dotted #409EFF;

  > * {
    padding: 10px
  }

  .intro {
    min-height: 100px;
    max-height: 38rem;
    overflow-y: scroll;
    cursor: pointer;

  }
}


.opt-box {
  width: 98%;
  //line-height: 40px;
  position: absolute;
  bottom: 2px;
  opacity: 0.3;
}

.opt-box:hover {
  opacity: 1;
  transition-duration: 0.3s;

}

.pptTitle {
  height: 20px;
  font-size: 20px;
  font-weight: bold;
  /*border: 1px solid red;*/
}

.stateImg {
  width: 250px;
  height: 250px;
  position: absolute;
  display: block;
  right: 0;
  z-index: 99;
  top: 30%;
}

.image-slot {
  text-align: center;
  margin: 50% auto;
  color: #7a8491;
}

.alertPage {
  position: absolute;
  z-index: 99;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  width: 500px;
  height: 100px;
  text-align: center;

  > img {
    width: 30px;
    height: 30px;
    vertical-align: middle;
    animation: change 3s;
    animation-iteration-count: infinite;
    margin-right: 10px;
  }


  --bg-size: 400%;
  --color-one: rgb(73, 47, 93);
  --color-two: #ff3176;
  font-size: clamp(1rem, 25vmin, 1rem);
  background: linear-gradient(
          90deg,
          var(--color-one),
          var(--color-two),
          var(--color-one)
  ) 0 0 / var(--bg-size) 100%;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  animation: move-bg 8s infinite linear;

}

@media (prefers-reduced-motion: no-preference) {
  .boujee-text {
    animation: move-bg 8s linear infinite;
  }
  @keyframes move-bg {
    to {
      background-position: var(--bg-size) 0;
    }
  }
}

@keyframes change {
  0% {
    transform: rotate(45deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}

::v-deep .labelClass {
  width: 180px;
  font-size: 16px;
  color: black;
}

::v-deep .el-descriptions-item {
  padding-bottom: 2px;
}

::v-deep .contentClass {
  width: 100%;
  display: inline;

  > span {
    color: black;
    font-size: 16px;
  }

  > i {
    display: inline-block;
    float: right;
    margin-right: 20px;
    line-height: 20px;
    cursor: pointer;
  }
}

.configure {
  //bottom: 10px;
  margin: 10px 0;
  //min-height: 40px;
  //border: 1px #ff3176 dotted;

  > b {
    //display: block;
    width: 80px;
    height: 120px;
    //margin-top: 10px;
    color: #8a8f97;
    font-size: 14px;
  }

  > span {
    margin-right: 10px;
  }
}

.box {
  position: relative;
}

.hightKickback {
  width: 230px;
  position: absolute;
  display: block;
  right: 160px;
  top: 10%;
  opacity: 0.8;
  //z-index: 99;
  //top: 30%;
  z-index: 0;

  > img {
    width: 100%;
    height: 100%;
    position: relative;
  }

  > span {
    width: 90px;
    position: absolute;
    top: 90px;
    color: white;
    left: 80px;
    font-size: 32px;
    line-height: 30px;
    text-align: center;
    font-style: italic;
  }
}
</style>
<style>
.tox-tinymce-aux {
  z-index: 999999 !important;
}
</style>
